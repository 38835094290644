<template>
  <div class="play-ground">
    <div class="editor text-start position-relative" :key="componentKey">
      <VueDragResize
        v-for="element in elements"
        :key="element.id"
        :parentLimitation="true"
        :w="element.width"
        :h="element.height"
        :x="element.x"
        :y="element.y"
        :isDraggable="mode == 'view' ? false : true"
        :isResizable="mode == 'view' ? false : true"
        :miny="10"
        @clicked="selectElement(element)"
        @dragstop="elementPositioning"
        @resizestop="elementPositioning"
      >
        <div class="element">
          <div
            class="actions"
            v-if="
              selectedElement &&
              selectedElement.id == element.id &&
              mode != 'view'
            "
          >
            <i @click="cloneElement(element)" class="fa-regular fa-clone"></i>
            <i
              @click="removeElement(element)"
              class="fa-regular fa-trash-can text-danger"
            ></i>
          </div>
          <p
            :class="element.payload.type + ' p-1 cursor-drag'"
            v-if="element.type == 'text'"
          >
            {{ element.payload.content }}
          </p>
          <img
            :src="element.payload.content"
            v-if="element.type == 'image'"
            :width="element.width"
            :height="element.height"
          />
        </div>
      </VueDragResize>
    </div>

    <ElementPanel
      :element="selectedElement"
      :templateId="id"
      :pageId="page.id"
      :text="selectedElement.payload.content"
      v-if="selectedElement"
      @ontype="handleRerender"
      @onClose="handleClose"
    ></ElementPanel>
  </div>
</template>

<script>
import ElementPanel from "@/components/editor/elementPanel/ElementPanel.vue";
import VueDragResize from "vue-drag-resize";
export default {
  name: "AppEditor",
  props: ["page", "mode"],
  components: {
    ElementPanel,
    VueDragResize,
  },
  computed: {
    elements() {
      return this.$store.getters.getPageElements(this.page.id, this.id);
    },
  },
  data: function () {
    return {
      componentKey: 0,
      id: this.$route.params.id,
      selectedElement: null,
    };
  },
  methods: {
    handleRerender() {
      this.componentKey += 1;
    },
    handleClose() {
      this.selectedElement = null;
    },
    selectElement(element) {
      if (this.mode != "view") {
        if (this.selectedElement) {
          this.selectedElement = null;
        }
        this.selectedElement = element;
      }
    },
    elementPositioning(el) {
      this.selectedElement = {
        ...this.selectedElement,
        height: el.height,
        width: el.width,
        x: el.left,
        y: el.top,
      };
      let payload = {
        templateId: this.id,
        pageId: this.page.id,
        element: this.selectedElement,
      };
      this.$store.dispatch("editElement", payload);
    },
    removeElement(el) {
      if (confirm("are you sure to remove this element?")) {
        let payload = {
          elementId: el.id,
          templateId: this.$route.params.id,
          pageId: this.page.id,
        };
        this.$store.dispatch("removeElement", payload);
        this.selectedElement = null;
      }
    },
    cloneElement(el) {
      let element = { ...el };
      element.y += 60;
      element.id = Math.floor(Math.random() * 9999999999);
      let payload = {
        parentId: this.$route.params.id,
        pageId: this.page.id,
        element,
      };
      this.$store.dispatch("addElement", payload);
    },
  },
};
</script>

<style scoped lang="scss">
.editor {
  background: #fff;
  height: 842px;
  width: 800px;
  margin: auto;
  margin-top: 3vh;
  .element {
    position: relative;
    img {
      object-fit: cover;
    }
    .actions {
      position: absolute;
      bottom: 100%;
      right: 0;
      z-index: 9999999999;
      i {
        cursor: pointer;
        display: inline-block;
        padding: 5px;
        &:hover {
          background: #eee;
          border-radius: 3px;
        }
      }
    }
  }
}
.cursor-drag {
  cursor: grabbing;
}
</style>
