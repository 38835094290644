<template>
  <div class="home mt-5">
    <div class="add-template" v-if="toggleAdd">
      <div class="card text-start">
        <div class="card-header"><b>Add New Template</b></div>
        <div class="card-body">
          <div class="form-group">
            <label>Template Title</label>
            <input type="text" v-model="templateTitle" class="form-control" />
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-info btn-sm" @click="addTemplate()">
            Add
          </button>
          <button class="btn text-danger btn-sm" @click="toggleAddTemplate()">
            Cancel
          </button>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h5 class="text-start float-start">Templates</h5>
          <div class="float-end">
            <button class="btn btn-primary btn-sm" @click="toggleAddTemplate()">
              Add Template
            </button>
          </div>
        </div>
        <div class="card-body p-5">
          <app-templates :templates="templates"></app-templates>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTemplates from "@/components/global/templates/Templates.vue";
export default {
  name: "HomeView",
  data: function () {
    return {
      toggleAdd: false,
      templateTitle: "",
    };
  },
  methods: {
    toggleAddTemplate() {
      this.toggleAdd = !this.toggleAdd;
    },
    addTemplate() {
      if (this.templateTitle != "") {
        let payload = {
          id: Math.floor(Math.random() * 9999999999),
          title: this.templateTitle,
          pages: [
            {
              id: 1,
              elements: [],
            },
          ],
          createdAt: Date(),
        };
        this.$store.dispatch("addTemplate", payload);
        this.templateTitle = "";
        this.toggleAdd = false;
      }
    },
  },
  computed: {
    templates: function () {
      return this.$store.getters.getTemplates;
    },
  },
  components: {
    AppTemplates,
  },
};
</script>

<style scoped lang="scss">
.add-template {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
