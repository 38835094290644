<template>
  <div class="side-bar">
    <div class="items">
      <div
        :class="'item ' + (toggleText ? 'active' : null)"
        @click="toggleTextMenu()"
      >
        <i class="fa-solid fa-font"></i>
      </div>
      <div
        :class="'item ' + (toggleMedia ? 'active' : null)"
        @click="toggleMediaMenu"
      >
        <i class="fa-regular fa-image"></i>
      </div>
    </div>
    <div class="text-menu text-start" v-if="toggleText">
      <div class="head"><i class="fa-solid fa-font"></i> Text List</div>
      <div @click="addElement('h1')" class="text h1">Text Example</div>
      <div @click="addElement('h2')" class="text h2">Text Example</div>
      <div @click="addElement('h3')" class="text h3">Text Example</div>
      <div @click="addElement('h4')" class="text h4">Text Example</div>
      <div @click="addElement('h5')" class="text h5">Text Example</div>
      <div @click="addElement('h6')" class="text h6">Text Example</div>
      <div @click="addElement('normal')" class="text">Text Example</div>
    </div>
    <div class="text-menu text-start" v-if="toggleMedia">
      <div class="head"><i class="fa-regular fa-image"></i> Media List</div>
      <div class="row">
        <div class="col-12" v-for="file in media" :key="file.id">
          <div class="media-element" @click="addElement('image', file.url)">
            <img :src="file.url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SettingsModal from "@/components/editor/sidebar/settingsModal/SettingsModal.vue";
export default {
  name: "SideBar",
  data: function () {
    return {
      toggleText: false,
      toggleMedia: false,
      id: this.$route.params.id,
    };
  },
  props: ["pageId"],
  methods: {
    toggleTextMenu() {
      this.toggleText = !this.toggleText;
      this.toggleMedia = false;
    },
    toggleMediaMenu() {
      this.toggleMedia = !this.toggleMedia;
      this.toggleText = false;
    },
    addElement(type = "text", extras = null) {
      let parentId = this.id;
      let payload = {
        parentId: parentId,
        pageId: this.pageId,
        element: {
          id: Math.floor(Math.random() * 9999999999),
          x: 10,
          y: 10,
          width: type == "image" ? 200 : 350,
          height: type == "image" ? 200 : 60,
          type: type != "image" ? "text" : "image",
          payload: {
            content: extras || "Text Example ",
            type,
          },
        },
      };
      this.$store.dispatch("addElement", payload);
    },
  },
  computed: {
    media() {
      return this.$store.getters.getMedia;
    },
  },
};
</script>

<style scoped lang="scss">
.side-bar {
  padding-top: 10px;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: #274064;
  z-index: 9;
  .items {
    .item {
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      font-size: 20px;
      padding: 10px 20px;
      color: #7587a1;
      &.active,
      &:hover {
        color: #d9dfe6;
      }
    }
  }
  .head {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .text-menu {
    position: absolute;
    top: 0;
    right: 100%;
    color: #ccc;
    background: #0b192e;
    height: 100%;
    width: 300px;
    padding-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999999999;
    .text {
      padding: 10px 20px;
      &:hover {
        color: #fff;
        cursor: pointer;
      }
    }
    .media-element {
      margin-bottom: 10px;
      padding: 10px;
      img {
        width: 100%;
        height: 100px;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }
}
</style>
