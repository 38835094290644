<template>
  <div class="templates">
    <div class="row">
      <div
        class="col-lg-3 col-md-4"
        v-for="template in templates"
        :key="template.id"
      >
        <single-template :template="template"></single-template>
      </div>
    </div>
  </div>
</template>

<script>
import SingleTemplate from "@/components/global/templates/single-template/single-template.vue";
export default {
  name: "AppTemplates",
  components: {
    SingleTemplate,
  },
  props: ["templates"],
};
</script>

<style scoped></style>
