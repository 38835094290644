<template>
  <div class="element-panel">
    <h4>
      <i class="fa fa-edit"></i> Edit Element
      <i
        class="float-end fa-regular fa-circle-xmark close"
        @click="closePanel"
      ></i>
    </h4>
    <div class="element-info">
      <p>X: {{ element.x }}</p>
      <p>Y: {{ element.y }}</p>
      <p>width: {{ element.width }}</p>
      <p>height: {{ element.height }}</p>
      <div v-if="element.type == 'text'" class="form-group">
        <label><b>Text</b></label>
        <textarea
          rows="4"
          v-model="elementContent"
          class="form-control"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElementPanel",
  props: ["element", "templateId", "pageId", "text"],
  data: function () {
    return {
      elementContent: this.element.payload.content,
    };
  },
  methods: {
    closePanel() {
      this.$emit("onClose", true);
    },
  },
  watch: {
    element() {
      console.clear();
      this.elementContent = this.element.payload.content;
    },
    elementContent() {
      let element = {
        ...this.element,
        payload: {
          content: this.elementContent,
          type: this.element.payload.type,
        },
      };
      let payload = {
        templateId: this.templateId,
        pageId: this.pageId,
        element,
      };
      this.$store.dispatch("editElement", payload);
      this.$emit("ontype", true);
    },
  },
};
</script>

<style scoped lang="scss">
.element-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 20px;
  text-align: left;
  background: #fff;
  z-index: 9;
  h4 {
    padding-bottom: 10px;
    font-size: 15px;
    border-bottom: 1px solid #eee;
  }
  .form-group {
    border-top: 1px solid #eee;
    padding-top: 15px;
    textarea {
      border-radius: 0 !important;
      resize: none;
    }
  }
  .close {
    cursor: pointer;
  }
}
</style>
