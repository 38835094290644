import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/style.scss";
Vue.config.productionTip = false;

Vue.filter("date", function (v) {
  let date = new Date(v);
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  return day + "-" + month + "-" + year;
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
