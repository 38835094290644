<template>
  <div class="pages-bar">
    <select v-model="page">
      <option
        :selected="page == item.id"
        :value="item.id"
        v-for="(item, index) in pages"
        :key="item.id"
      >
        Page {{ index + 1 }}
      </option>
    </select>
    <button @click="addPage"><i class="fa-regular fa-square-plus"></i></button>
  </div>
</template>

<script>
export default {
  name: "PagesBar",
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    pages() {
      return this.$store.getters.getPages(this.$route.params.id);
    },
  },
  methods: {
    addPage() {
      this.$store.dispatch("addPage", { templateId: this.$route.params.id });
      this.$emit("onPageChanged", this.pages[this.pages.length - 1].id);
      this.page = this.pages[this.pages.length - 1].id;
    },
  },
  watch: {
    page(v) {
      this.$emit("onPageChanged", v);
    },
  },
};
</script>

<style scoped lang="scss">
.pages-bar {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #274064;
  button {
    padding: 10px;
    background: transparent;
    border: #0b192e;
    color: #7587a1;
    &:hover {
      color: #a2b2cb;
    }
  }
  select {
    margin: 10px;
  }
}
</style>
