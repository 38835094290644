import { render, staticRenderFns } from "./single-template.vue?vue&type=template&id=cea362ae&scoped=true&"
import script from "./single-template.vue?vue&type=script&lang=js&"
export * from "./single-template.vue?vue&type=script&lang=js&"
import style0 from "./single-template.vue?vue&type=style&index=0&id=cea362ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cea362ae",
  null
  
)

export default component.exports