import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    templates: [],
    media: [
      {
        id: 1,
        url: "https://images.pexels.com/photos/590016/pexels-photo-590016.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
      {
        id: 2,
        url: "https://images.pexels.com/photos/5324968/pexels-photo-5324968.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
      {
        id: 3,
        url: "https://images.pexels.com/photos/6347724/pexels-photo-6347724.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
    settings: {
      mainColor: "#000",
      background: null,
    },
  },
  getters: {
    getMedia(state) {
      return state.media;
    },
    getTemplates(state) {
      return state.templates;
    },
    getTemplate: (state) => (id) => {
      return state.templates.find((item) => item.id == id);
    },
    getPages: (state) => (id) => {
      return state.templates.find((item) => item.id == id).pages;
    },
    getPageElements: (state) => (pageId, templateId) => {
      let tempIndex = state.templates.findIndex(
        (item) => item.id == templateId
      );
      let pageIndex = state.templates[tempIndex].pages.findIndex(
        (page) => page.id == pageId
      );
      return state.templates[tempIndex].pages[pageIndex].elements;
    },
    getElement: (state) => (pageId, templateId, elementId) => {
      let tempIndex = state.templates.findIndex(
        (item) => item.id == templateId
      );
      let pageIndex = state.templates[tempIndex].pages.findIndex(
        (page) => page.id == pageId
      );
      let elementIndex = state.templates[tempIndex].pages[
        pageIndex
      ].elements.findIndex((element) => element.id == elementId);
      return state.templates[tempIndex].pages[pageIndex].elements[elementIndex];
    },
  },
  mutations: {
    addTemplate(state, payload) {
      state.templates.push(payload);
    },
    addPage(state, payload) {
      let templateIndwx = state.templates.findIndex(
        (item) => item.id == payload.templateId
      );
      state.templates[templateIndwx].pages.push({
        id: Math.floor(Math.random() * 9999999),
        elements: [],
      });
    },
    removeTemplate(state, payload) {
      let index = state.templates.findIndex((item) => item.id != payload.id);
      state.templates.splice(index, 1);
    },
    addElement(state, payload) {
      let index = state.templates.findIndex(
        (item) => item.id == payload.parentId
      );
      let pageIndex = state.templates[index].pages.findIndex(
        (item) => item.id == payload.pageId
      );
      state.templates[index].pages[pageIndex].elements.push(payload.element);
    },
    editElement(state, payload) {
      let pageIndex;
      let elementIndex;
      let templateIndex;
      if (payload.templateId) {
        templateIndex = state.templates.findIndex(
          (item) => item.id == payload.templateId
        );
      }
      pageIndex = state.templates[templateIndex].pages.findIndex(
        (item) => item.id == payload.pageId
      );
      elementIndex = state.templates[templateIndex].pages[
        pageIndex
      ].elements.findIndex((item) => item.id == payload.element.id);

      state.templates[templateIndex].pages[pageIndex].elements[elementIndex] = {
        ...payload.element,
        payload: { ...payload.element.payload },
      };
    },
    removeElement(state, payload) {
      let templateIndex = state.templates.findIndex(
        (item) => item.id == payload.templateId
      );
      let pageIndex = state.templates[templateIndex].pages.findIndex(
        (item) => item.id == payload.pageId
      );
      let elementIndex = state.templates[templateIndex].pages[
        pageIndex
      ].elements.findIndex((item) => item.id == payload.elementId);
      state.templates[templateIndex].pages[pageIndex].elements.splice(
        elementIndex,
        1
      );
    },
  },
  actions: {
    addTemplate(context, payload) {
      context.commit("addTemplate", payload);
    },
    removeTemplate(context, payload) {
      context.commit("removeTemplate", payload);
    },
    addElement(context, payload) {
      context.commit("addElement", payload);
    },
    editElement(context, payload) {
      context.commit("editElement", payload);
    },
    removeElement(context, payload) {
      context.commit("removeElement", payload);
    },
    addPage(context, payload) {
      context.commit("addPage", payload);
    },
  },
  modules: {},
});
