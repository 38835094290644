<template>
  <div class="single-template text-start">
    <div class="card">
      <div class="card-header">{{ template.title }}</div>
      <div class="card-body">
        <p>Created at: {{ template.createdAt | date }}</p>
        <router-link
          class="btn btn-info btn-sm"
          :to="'/template/' + template.id"
        >
          open
        </router-link>
        <button
          class="btn btn-sm text-danger"
          @click="removeTemplate(template.id)"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "single-template",
  props: ["template"],
  methods: {
    removeTemplate(id) {
      if (confirm("are you sure?")) {
        this.$store.dispatch("removeTemplate", { id });
      }
    },
  },
};
</script>

<style scoped>
.single-template {
  margin-bottom: 10px;
}
</style>
