<template>
  <div>
    <div class="alert alert-danger" v-if="!template">Invalid Template</div>
    <div class="editor" v-if="template">
      <div class="container">
        <div class="top-nav text-start">
          <b>{{ template.title }}</b>
          <div class="float-end">
            <router-link class="btn btn-sm btn-outline-success" to="/"
              ><i class="fa-solid fa-house"></i> Back to templates
            </router-link>
            <button
              @click="generateReport()"
              class="btn btn-sm btn-outline-primary ms-2"
            >
              <i class="fa fa-download"></i> Download
            </button>
          </div>
        </div>
        <app-editor
          :page="template.pages && template.pages[pageIndex]"
        ></app-editor>
      </div>
      <side-bar :pageId="pageId"></side-bar>
      <PagesBar @onPageChanged="handlePage"></PagesBar>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :filename="template.title"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="595px"
        ref="html2Pdf"
      >
        <section slot="pdf-content" :key="componwntKey">
          <section class="pdf-item" v-for="(page, i) in pages" :key="page.id">
            <app-editor
              mode="view"
              :page="template.pages && template.pages[i]"
            ></app-editor>
            <div class="html2pdf__page-break" />
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import AppEditor from "@/components/editor/editor/Editor.vue";
import SideBar from "@/components/editor/sidebar/SideBar.vue";
import PagesBar from "@/components/editor/pagesBar/PagesBar.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "EditorView",
  data: function () {
    return {
      id: this.$route.params.id,
      pageIndex: 0,
      pageId: 1,
      componwntKey: 0,
    };
  },
  computed: {
    template: function () {
      return this.$store.getters.getTemplate(this.id);
    },
    pages: function () {
      return this.$store.getters.getPages(this.id);
    },
  },
  components: {
    AppEditor,
    SideBar,
    PagesBar,
    VueHtml2pdf,
  },
  methods: {
    handlePage(v) {
      let pageIndex = this.template.pages.findIndex((item) => item.id == v);
      this.pageIndex = pageIndex;
      this.pageId = v;
    },
    generateReport() {
      this.componwntKey += 1;
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped lang="scss">
.top-nav {
  padding: 10px;
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0 10px 10px rgba(#d9dfe6, 0.1);
  z-index: 9;

  .btn {
    margin-top: -3px;
  }
}
</style>
